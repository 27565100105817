#home {
    background-color: #e8e8e8 !important;
    flex-grow: 1;
    overflow-y: auto;
}

#home.mobile {
    width: 100%;
}

#home.desktop {
    width: calc(100% - 256px);
    margin-left: 256px;
}

#homeContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

#appContent {
    display: flex;
    overflow-y: auto;
    flex-grow: 1;
    width: 100%;
}

#appContent > div {
    flex-grow: 1;
}

#fab {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px;
    z-index: 2;
}

.action {
    white-space: nowrap;
    display: block;
}

#popup {
    z-index: 2000;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: flex-end;
    flex-flow: column-reverse;
    transition-property: opacity;
}
