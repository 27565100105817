.header {
    text-align: center;
    padding: 4px 0px;
}

#divider {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
}

#divider > span {
    position: relative;
    display: inline-block;
    font-style: italic;
    padding: 0px 8px;
}

#divider > span:before,
#divider > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: rgba(0, 0, 0, 0.88);
}

#divider > span:before {
    right: 100%;
    margin-right: 15px;
}

#divider > span:after {
    left: 100%;
    margin-left: 15px;
}

#generated {
    display: block;
    padding: 8px;
    font-weight: bold;
    margin: 8px 0px;
    text-align: center;
    background: rgba(0, 0, 0, 0.11);
    border: solid 1px rgba(0, 0, 0, 0.88);
}
