.container {
    padding: 16px;
}

@keyframes :local(animation) {
      0% {transform: rotate(1deg);}
    100% {transform: rotate(360deg);}
}

.loading {
    animation: animation;
    animation-iteration-count: infinite;
    animation-duration: 400ms;
    animation-timing-function: linear;
}
