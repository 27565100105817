:local #popup {
    padding: 0px 8px;
}

:local .item {
    display: flex;
    width: 100%;
}

:local .item > div:first-child {
    width: 25%;
    text-align: left;
}

:local .item > div {
    width: 75%;
    text-align: left;
}
