.noBorder {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 24px !important;
}

.noBorder > button {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 24px !important;
}

.listitem {
    background-color: white;
}

.tableitem {
    background-color: red;
}

.gridlist {
    margin: 0px !important;
    width: 100%;
}
