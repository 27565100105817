.breadcrumb {
    display: flex;
    height: 64px;
    flex-grow: 1;
}

.breadcrumb div {
    display: flex;
    align-items: center;
}

.breadcrumb > div:last-child {
    font-weight: bold;
    line-height: normal;
}

.breadcrumb > div > button span {
    text-transform: none !important;
}

.breadcrumb > div > button[disabled] span {
    display: block;
}
