.stripeElement {
    --green: #4caf50;
    margin-bottom: 10px;
    font-size: 0.7em;
    display: flex;
    flex-direction: column;
}

.stripeTabIcon {
    font-size: 1.2em;
}

.stripeTab {
    display: flex;
    flex-direction: row;
    justify-content: left;
    min-width: 14em;
}

.stripeContent {
    padding: 10px 5px;
    border: solid 2px var(--green);
    border-radius: 10px;
    border-top-left-radius: 0px;
}

.stripeTabContent {
    color: white;
    padding: 5px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--green);
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
}

.stripeTabSpace {
    flex-grow: 1;
}

.stripeTabContent a {
    text-decoration: none;
    font-weight: bolder;
    color: #5469d4;
}

.stripeTabContent a::before {
    content: "\00a0";
}
