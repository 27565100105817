@media screen and (min-width: 490px) {
    .container {
        width: 490px;
    }
    .container-content {
        padding-left: 32px;
        padding-right: 32px;
    }
}

body {
    overflow: hidden;
}

@media screen and (max-width: 490px) {
    .container-content {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.container-content {
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

fieldset {
    margin-bottom: 20px;
}

.container-content fieldset {
    margin-bottom: 0;
}

::-ms-clear, ::-ms-reveal {
    display: none;
}

.container input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #f0f0f0 inset;
}
