#loading {
    width: 100%;
}

#loading-progress {
    position: fixed;
    top: 72px;
    left: calc(100vw / 2 - 24px);
    z-index: 100;
    width: 48px;
    height: 48px;
    padding: 4px;
    border-radius: 50%;
}

@media screen and (min-width: 490px) {
    #loading-progress {
        top: 152px;
    }
}

.loading-show {
    opacity: 1;
    transition: opacity 200ms ease-out;
}

.loading-hide {
    opacity: 0;
}
