.popup-layer {
    z-index: 1301;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100vw;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    display: flex;
    transition: opacity 200ms ease-in;
    opacity: 0;
}

.popup-title {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.popup-layer-opened {
    opacity: 1;
}

@media screen and (min-width: 490px) {
    .popup-holder {
        max-width: 490px;
        position: fixed;
        z-index: 1302;
        right: 10vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 490px) {
    .popup-holder {
        width: 100%;
        margin: 0px;
    }
}

.popup-holder {
    bottom: 0;
    position: absolute;
    padding: 0px;
    width: 100%;
    max-height: 100%;
    transition: bottom 200ms ease-in;
    display: flex;
    flex-direction: column;
}

.popup-content {
    width: 100%;
    overflow-y: auto;
}

.popup-holder-opening {
    bottom: -500px;
}
