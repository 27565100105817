.total {
    text-align: center;
    font-weight: bolder;
}

.header {
    font-weight: bolder;
}

.headerRoot {

}
