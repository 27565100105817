:local .app {
    font-weight: bold !important;
}

:local .opened {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

:local .closed {
}

:local .item {
    height: 26px !important;
}

:local .active {
    background-color: rgba(0, 0, 0, 0.1) !important;
    font-weight: bold !important;
}

:local .inactive {
}

:local .icon {
    margin-left: 16px;
    margin-right: 16px;
}

:local .down {
    transform: none;
}

:local .right {
    transform: rotate(-90deg);
}

:local .pointer {
    cursor: pointer;
}
